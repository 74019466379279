
export const API_AUTH = {
    login: '/api/login',
    register: '/api/register',
    resetPassword: '/api/reset-password',
    changePassword: '/api/reset-password/change-password',
    forgottenPassword: '/api/reset-password/forgotten-password',
    verify: '/api/register/confirm',
    refreshToken: '/api/login/refresh-token'
};

export const API_INVITE = {
    invite: '/api/invitation/generate',
    getAll: '/api/invitation/get-all',
    approveByManager: '/api/invitation/approve-by-manager',
};


export const API_USER = {
    details: '/api/user',
    profile: '/api/user/profile',
    experience: '/api/user/experience',
    update: '/api/user/update-details',
    contactInfo: '/api/user/contact-info'
};

export const API_DOCUMENT = {
    all: '/api/documents',
}

export const API_DISCIPLINARY = {
    root: '/api/disciplinary',
    editDisciplinary: '/api/disciplinary/edit-disciplinary',
    history: '/api/disciplinary/history',
    managerDisciplinaryCards: '/api/manager-disciplinary/cards',
    managerDisciplinarySuspends: '/api/manager-disciplinary/suspends',
}

export const API_USER_ADMINISTRATION = {
    all: '/api/administration/user/get-all',
    search: '/api/administration/user/search',
    contacts: '/api/administration/user/export-contacts',
    update: '/api/administration/user/update',
    delete: '/api/administration/user/delete-user',
    removeFromTeams: '/api/administration/user/remove-from-teams',
    getIdentityCard: '/api/administration/user/get-identity-card',
    disabledSuspend: '/api/administration/user/disabled-suspend',
    checkRiskAssessment: '/api/administration/user/check-risk-assessment',

}

export const API_ANALYTIC = {
    usersAnalytic: '/api/analytics/user',
    countUsersEnabled: '/api/analytics/count-users-enabled',
    teamsAnalytic: '/api/analytics/teams',
    leagueStatistics: '/api/analytics/league-statistics',
    financialStatistics: '/api/analytics/financial-statistics',
    userFinancialStatistics: '/api/analytics/user-financial',
    totalIncome: '/api/analytics/total-income',
}

export const API_TEAM = {
    add: '/api/football-team/create-team',
    edit: '/api/football-team/edit-team',
    yourTeams: '/api/football-team/get-teams',
    teamsByLeague: '/api/football-team/get-teams-by-league',
    nameOfTeams: '/api/football-team/name-of-teams',
    yourTeamsForInvite: '/api/football-team/get-teams-for-invite',
    getInvitedPlayers: '/api/football-team/get-invited-players/',
    getPlayers: '/api/football-team/get-players',
    getPlayersForTransfer: '/api/football-team/get-players-for-transfer',
    addPlayer: '/api/football-team/add-player',
    addPlayerByAdmin: '/api/football-team/add-player-by-admin',
    updateProfile: '/api/football-team/update-profile',
    removePlayers: '/api/football-team/remove-players',

}

export const API_TEAM_ADMINISTRATION = {
    all: '/api/football-team-administration/get-all',
    update: '/api/football-team-administration/update-by-admin',
    changeManager: '/api/football-team-administration/change-manager',
    managersWithoutTeam: '/api/football-team-administration/managers-without-team',
    getTeamsForLeague: "/api/football-team-administration/get-teams-for-league",
    getTeamsForFilter: "/api/football-team-administration/get-teams-for-filter",
    overdueTax: "/api/football-team-administration/overdue-tax",
    blockTeam: "/api/football-team-administration/block-team",
}

export const API_COMPETITION = {
    all: '/api/competitions',
    update: '/api/competitions/update'
}

export const API_EDITIONS = {
    all: '/api/competitions/editions',
    allEditions: '/api/competitions/editions/all',
    currentEditions: '/api/competitions/editions/current-editions',
    update: '/api/competitions/editions/update',
}

export const API_LEAGUES = {
    all: '/api/competitions/editions/leagues',
    allByCompetitionUuid: '/api/competitions/editions/leagues/by-competition',
    add: '/api/competitions/editions/leagues/add-team',
    update: '/api/competitions/editions/leagues/update',
    sequenceTeam: '/api/competitions/editions/leagues/sequence-teams',
    getSequenceNumber: "/api/competitions/editions/leagues/sequence-number",
    generateTournament: "/api/competitions/editions/leagues/stages/generate-stages-matches",
    getStageNumbers: "/api/competitions/editions/leagues/stages/stage-numbers",
    getPrefilteredStagesAndMatches: "/api/competitions/editions/leagues/stages/prefiltered",
}

export const API_CUP = {
    all: '/api/competitions/editions/cup-administration/all',
    add: '/api/competitions/editions/cup-administration/add',
    edit: '/api/competitions/editions/cup-administration/edit',
    getToursByUuid: '/api/competitions/editions/cup-administration/get-tours',
    getAllTours: '/api/competitions/editions/cup-administration/get-all-tours',
    generateMatches: '/api/competitions/editions/cup-administration/generate-matches-ranking',
    addTour: '/api/competitions/editions/cup-administration/add-tour',
    editTour: '/api/competitions/editions/cup-administration/edit-tour',
    getAllTeamsNotInTour: '/api/competitions/editions/cup-administration/get-teams-for-tour',
    getAllTeamsInTour: '/api/competitions/editions/cup-administration/get-teams-in-tour',
    addTeamToTour: '/api/competitions/editions/cup-administration/add-team-to-tour',
    deleteTeamFromTour: '/api/competitions/editions/cup-administration/delete-team-from-tour',
}

export const API_FIELDS = {
    all: '/api/fields/all',
    add: '/api/fields/add',
    edit: '/api/fields/edit',
    delete: '/api/fields/delete',
}

export const API_MATCH_HOURS = {
    all: '/api/match-hours/all',
    add: '/api/match-hours/add',
    delete: '/api/match-hours/delete',
}

export const API_LEAGUE_TAG = {
    all: '/api/tag-league/all',
    add: '/api/tag-league/add',
    edit: '/api/tag-league/edit',
    disableTag: '/api/tag-league/disable-tag',
}

export const API_STAFF = {
    all: '/api/staff-matches/all',
    add: '/api/staff-matches/add',
    edit: '/api/staff-matches/edit',
}

export const API_MATCH = {
    scheduleMatch: '/api/matches/schedule',
    cancelMatch: '/api/matches/cancel',
    allScheduledMatches: '/api/matches/all-scheduled-matches',
    mediaLinks: '/api/matches/add-media-links',
    cloudLink: '/api/matches/add-cloud-link',
    changeStatus: '/api/matches/status-match',
    getscheduledMatch: '/api/matches/scheduled-match',
    getScore: '/api/matches/score-by-match',
    getYoutubeVideos: '/api/matches/photo-videos',
    addMatchToCupTour: '/api/matches/add-match-to-cup',
    cupMatches: '/api/matches/cup-matches',
    addStaff: '/api/matches/add-staff',
    updateStaff: '/api/matches/update-staff',
    getStaff: '/api/matches/get-staff',
    inQueueMatches: '/api/matches/in-queue-matches',
    countInQueueMatches: '/api/matches/count-in-queue-matches',
    publicInQueueMatches: '/api/matches/public-in-queue-matches',
}

export const API_MATCH_EVENT = {
    root: '/api/matches/event',
    add: '/api/matches/event/add-event',
    changeStatus: '/api/matches/status-match',
}

export const API_MATCH_STATISTICS = {
    add: '/api/match-statistics/add',
    edit: '/api/match-statistics/edit',
    getByMatchAndTeam: '/api/match-statistics/get-by-match-and-team',
}

export const API_MATCH_DETAIL_PLAYER = {
    all: '/api/match-detail-player/all-matches-team',
}

export const API_LINEUP = {
    getByMatch: '/api/lineup/get-by-match',
    getPlayers: '/api/lineup/get-players-by-team',
    getPlayersLineup: '/api/lineup/lineup-get-players',
    add: '/api/lineup/create',
    update: '/api/lineup/update',
}

export const API_FINANCIAL = {
    allTax: '/api/financial/all-tax',
    allCredits: '/api/financial/all-credits',
    allTaxUser: '/api/user-financial/all',
    allManagers: '/api/financial/all-managers',
    allExpenses: '/api/financial/all-expenses',
    distinctFootballTeams: '/api/financial/distinct-football-teams',
    distinctManagers: '/api/financial/distinct-managers',
    addTax: '/api/financial/add',
    deleteTax: '/api/financial/delete',
    updateTax: '/api/financial/update',
    addCredits: '/api/financial/add-credits',
    addExpense: '/api/financial/add-expense',
    collectTax: '/api/financial/collect-tax',
    deleteExpense: '/api/financial/delete-expense',
    updateExpense: '/api/financial/update-expense',
    matchTax: '/api/financial/match-tax',
    overdueTax: '/api/financial/overdue-tax',
    overdueTeamTaxes: '/api/financial/overdue-team-tax',
}

export const API_FINANCIAL_HISTORY = {
    creditHistory: '/api/payments-history/credits',
}

export const API_NEWS = {
    all: '/api/news/all',
    add: '/api/news/add',
    generateNews: '/api/news/generate-news',
    update: '/api/news/update',
    delete: '/api/news/delete'
}

export const API_CHRONIC = {
    all: '/api/chronic/all',
    add: '/api/chronic/add-chronic',
    update: '/api/chronic/update-chronic',
}

export const API_RANKING = {
    getByLeague: '/api/ranking/get-by-league',
    getByCupTour: '/api/ranking/get-by-cupTour',
    teamSubmission: '/api/ranking/team-submission',
}

export const API_GENERAL_SETTINGS = {
    allSettings: '/api/general-settings/all-settings',
    addUpdate: '/api/general-settings/add-update',
}

export const API_TRANSFER = {
    allTransfer: '/api/transfer/all-transfers',
    requestTransfer: '/api/transfer',
    requestTransferNoTeam: '/api/transfer/no-team',
    requestedByManager: '/api/transfer/requested-by-manager',
    requiresManagerApproval: '/api/transfer/requires-manager-approval',
    approveTransferByManager: '/api/transfer/approve-transfer-by-manager',
    approveTransferByAdmin: '/api/transfer/approve-transfer-by-admin',
    deleteTransfer: '/api/transfer/delete-transfer',
    noTeam: '/api/transfer/no-team',
    noTeamAdminView: '/api/transfer/no-team-admin-view',
};

export const API_PARTNERS = {
    addCategory: '/api/partners/add-category',
    editCategory: '/api/partners/edit-category',
    allCategory: '/api/partners/all-category',
    allPartners: '/api/partners/all-partners',
    allPartnersAward: '/api/partners/all-partners-award',
    addPartner: '/api/partners/add-partner',
    editPartner: '/api/partners/edit-partner',
    disablePartner: '/api/partners/disable-partner',
};

export const API_AWARD = {
    all: '/api/awards/all',
    add: '/api/awards/add',
    addArticle: '/api/awards/add-article',
    editArticle: '/api/awards/edit-article',
    article: '/api/awards/article',
    edit: '/api/awards/edit',
}

export const API_FAQ = {
    allCategory: '/api/faq/all-categories',
    allFaqs: '/api/faq/all-faqs',
    addCategory: '/api/faq/add-category',
    editCategory: '/api/faq/edit-category',
    addFaq: '/api/faq/add-faq',
    editFaq: '/api/faq/edit-faq',
    deleteFaq: '/api/faq/delete-faq',
}

export const API_MENU = {
    all: '/api/menus/all',
    add: '/api/menus/add',
    edit: '/api/menus/edit',
    addMenuItem: '/api/menus/add-menu-item',
    allMenuItems: '/api/menus/all-menu-items',
    allLeaguesAndCups: '/api/menus/all-leagues-and-cup',
};

export const API_EDITION_JOURNAL = {
    all: '/api/edition-journal/all',
    statistics: '/api/edition-journal/statistics',
    add: '/api/edition-journal/add',
    edit: '/api/edition-journal/edit',
    allByManager: '/api/edition-journal/all/manager',
    confirm: '/api/edition-journal/confirm',
}

export const API_EDITION_JOURNAL_PLAYER = {
    findPlayers: '/api/edition-journal-player/search-players',
    addPlayer: '/api/edition-journal-player/add-player',
    playersAdded: '/api/edition-journal-player/players-added',
    allPlayersAdded: '/api/edition-journal-player/all-players-added',
    confirmationsOnTeam: '/api/edition-journal-player/confirmations-on-team',
    confirm: '/api/edition-journal-player/confirm',
    confirmByAdmin: '/api/edition-journal-player/confirm-by-admin',
    delete: '/api/edition-journal-player/delete',
}

export const API_DOCS_ADMIN = {
    all: '/api/docs-administration/all',
    allForWizard: '/api/docs-administration/all-for-wizard',
    allForUsers: '/api/docs-administration/doc-users',
    add: '/api/docs-administration/add',
    edit: '/api/docs-administration/edit',
}

